import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { useHistory } from "react-router-dom";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);


export default function AppDash() {
  const history = useHistory();
  const classes = useStyles();
  const [appdata, setAppData] = React.useState([]);
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  const credit_dash = (e)=>{
    console.log(e);
    sessionStorage.setItem('selected_client', e);
    if(sessionStorage.getItem('selected_client')){ 
      history.push("/creditadmin/dashboard");
    }
  }

  const [accessToken, setAccessToken] = React.useState(localStorage.getItem('jwtAccessTokenLocalStorage'));
  var jwt_access_token_json = JSON.parse(accessToken);
  React.useEffect(() => {

    async function fetchData(){
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer '+jwt_access_token_json['value']
        }
      };
      const responsedata = await fetch(
        process.env.REACT_APP_SERVER_URL+ "/oauth/list_authorized_clients", 
        requestOptions).then((response)=>{
          if (response.status === 401){
            localStorage.removeItem('jwtAccessTokenLocalStorage');
            history.push('/auth/');
          }
          if (response.status >= 400 && response.status < 600) {
            // throw new Error("Bad response from server");
            console.log(response.json())
          }
          return response;
        }).then((returnedResponse)=>{
          return returnedResponse;
        }).catch((error)=>{
          console.log(error)
        });
      const clients = await responsedata.json();
      setAppData(clients.clients);
    }
    fetchData();
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    return function cleanup() {
      window.clearTimeout(id);
    };
  }, []);
  var rows = [];
  for (var i = 0; i < appdata.length; i++) {
    const metadata = appdata[i]['application_name'];
    const app_json = JSON.parse(metadata)
    const client_id = appdata[i]['client_id'];
    rows.push(
      <GridItem key={client_id} xs={12} sm={12} lg={6}>
        <Card pricing>
          <CardBody>
            <h6 className={classes.cardCategory}>{app_json['client_name']}</h6>
            <div className={classes.icon}>
              <AccountBalanceIcon className={classes.iconRose} />
            </div>
            <Button color="danger" id={client_id} onClick={()=>credit_dash(client_id)}>
              Enter
            </Button>
          </CardBody>
        </Card>
      </GridItem>
    );
  } 

  return (
    <div  className={classes.container}>
     <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <GridContainer>
           {rows} 
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}
