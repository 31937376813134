import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import EnhancedTableServePage from './EnhancedTableServerPage'


function BankAccount(){

  const [data, setData] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [selected, setSelected] = React.useState(sessionStorage.getItem('selected_client'));
  const [clnsecret, setClnSecret] = React.useState(sessionStorage.getItem('client_secret'));
  const [authToken, setAuthToken] = React.useState(localStorage.getItem('auth_token'));
  var saved_token = JSON.parse(authToken);

  const fetchIdRef = React.useRef(0);

  async function fetchToken(){
  
    const details ={
      'grant_type': 'refresh_token',
      'refresh_token': saved_token['refresh_token'],
    }
    var formBody = [];
    for (var property in details){
      var encodedKey = encodeURIComponent(property);
      var encodedVal = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedVal);
    }
    const basic_secret = Buffer.from(selected+":"+clnsecret).toString('base64')
    formBody = formBody.join("&");
    const token_requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          'Authorization': 'Basic '+ basic_secret 
        },
        body: formBody
    }; 
    const token_response = await fetch( process.env.REACT_APP_SERVER_URL+ 
    "/oauth/oauth_token",
    token_requestOptions).then((response)=>{
      if (response.status === 401) {
        console.log(response.json());
      }
      if (response.status >= 400 && response.status < 600) {
        // throw new Error("Bad response from server");
        console.log("error")
      }
      return response;
      }).then((returnedResponse)=>{
        return returnedResponse;
      }).catch((error)=>{
        console.log(error)
      });
      const auth_token = await token_response.json();
      localStorage.setItem('auth_token', JSON.stringify(auth_token));
    };

  const fetchData = React.useCallback(({pageIndex, pageSize})=> {

    const fetchId = ++fetchIdRef.current;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+ saved_token['access_token'] 
      },
      body: JSON.stringify(
        { 
          "client_id": selected,
           "page_number": pageIndex,
           "item_per_page": pageSize
        }
      )
    };
    async function getAllBankAccounts(rqOptions){
      const dataresponse = await fetch(process.env.REACT_APP_SERVER_URL+ "/stonecastle/bank_account/all_bank_accounts", 
      rqOptions).then((response)=>{
          
          if (response.status >= 400 && response.status < 600) {
            console.log("error");
            throw new Error("Bad response from server");
          }
          return response;
        }).then((returnedResponse) => {
          return returnedResponse;
        }).catch((error) =>{
          console.log(error);
        });
      
        const dataret = await dataresponse.json()
        setData(dataret['bank_accounts'])
        setPageCount(dataret['total_pages'])
    }
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        try{
          getAllBankAccounts(requestOptions);
        }catch(e){
          fetchToken();
          getAllBankAccounts(requestOptions);
        }
      }
      }, 1000);
      
  }, []);
  

  const columns = React.useMemo(
    () => [
      // {
      //   Header: 'Bank_ID',
      //   accessor: 'bank_id',
      // },
      {
        Header: 'Bank_Account_ID',
        accessor: 'account_id',
      },
      {
        Header: 'Minimum Value',
        accessor: 'account_min_val.value',
      },
      {
        Header: 'Maximum Value',
        accessor: 'account_max_val.value',
      },
      {
        Header: 'Balance',
        accessor: 'account_balance.value',
      },
      {
        Header: 'Current Rate',
        accessor: 'current_rate.rate_val',
      },
      {
        Header: 'Latest Update',
        accessor: 'last_modified_timestamp',
      },
      {
        Header: 'Upload User',
        accessor: 'uploadusername',
      } 
    ],
    []
  );

  return (
    <div>
      <CssBaseline />
      <EnhancedTableServePage
        columns={columns}
        data={data}
        fetchData = {fetchData}
        pageCount= {pageCount}
      />
    </div>
  )
}

export default BankAccount;
