import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { useForm } from "react-hook-form";
import {Input, FormGroup, Form, Label} from 'reactstrap';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import {ListGroup, ListGroupItem, InputGroup} from 'reactstrap';
import axios from 'axios';

const useStyles = makeStyles(styles);
// using the local storage which would never expire until user clear. 
// https://www.robinwieruch.de/local-storage-react

const useStateWithLocalStorage = localStorageKey => {
    const [value, setValue] = React.useState(
      localStorage.getItem(localStorageKey) || ''
    );
   
    React.useEffect(() => {
      localStorage.setItem(localStorageKey, value);
    }, [value]);
   
    return [value, setValue];
  };
 // use session storage which would expire after the user close the tab 
const useStateWithSessionStorage = localStorageKey => {
    const [value, setValue] = React.useState(
      sessionStorage.getItem(localStorageKey) || ''
    );
   
    React.useEffect(() => {
        sessionStorage.setItem(localStorageKey, value);
    }, [value]);
   
    return [value, setValue];
  };
   
function OAuthApplication(){
    const classes = useStyles();
    const [appdata, setAppData] = React.useState([]);
    const [accessToken, setAccessToken] = React.useState(localStorage.getItem('auth_token'));
    var access_token_json = JSON.parse(accessToken);
    const [selected, setSelected] = React.useState(sessionStorage.getItem('selected_client'));


  React.useEffect(() => {
    async function fetchData(){
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer '+access_token_json['access_token']
        },
        body: JSON.stringify({ 
          "client_id": selected 
         }),
      };
      const response = await fetch(process.env.REACT_APP_SERVER_URL+ "/oauth/apps", 
      requestOptions).then((response)=>{
        // if (response.status === 401){
        //   localStorage.removeItem('jwtAccessTokenLocalStorage');
        //   history.push('/auth/');
        // }
        if (response.status >= 400 && response.status < 600) {
          // throw new Error("Bad response from server");
          console.log("error");
        }
        return response;
      }).then((returnedResponse)=>{
        return returnedResponse;
      }).catch((error)=>{
        console.log(error)
      });
      const data = await response.json();
      setAppData(data.data);
    }
    fetchData();
  }, []);

    return (
    <div>
    <GridContainer>

    <GridItem xs={6} sm={6} md={6} lg={6}>
        <Card>
        <CardHeader color="rose">
            <h4 className={classes.cardIconTitle}>Current App Information</h4>
        </CardHeader>
        <CardBody>
              <ListGroup>
              <ListGroupItem>Client_ID:{appdata.client_id}</ListGroupItem>
              <ListGroupItem>Client_Secret:{appdata.client_secret}</ListGroupItem>
              <ListGroupItem>App registration since:{appdata.app_registration_since}</ListGroupItem>
            </ListGroup>
        </CardBody>
        </Card> 
      </GridItem>
      </GridContainer>
    </div>
    );
}

export default OAuthApplication;