import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import React from 'react';

function castleAPI(){
    return(<div>
            <SwaggerUI url="/castlestone_api.json" />
        </div>);
    
};

export default castleAPI;