import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';

import { useHistory } from "react-router-dom";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {ListGroup, ListGroupItem} from 'reactstrap';

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { local } from "d3";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const history = useHistory();
  const [appdata, setAppData] = React.useState([]);
  const [accessToken, setAccessToken] = React.useState(localStorage.getItem('jwtAccessTokenLocalStorage'));
  const [selected, setSelected] = React.useState(sessionStorage.getItem('selected_client'));
  var jwt_access_token_json = JSON.parse(accessToken);
  React.useEffect(() => {

    async function fetchData(){
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer '+jwt_access_token_json['value']
        }
      };
      const response = await fetch( process.env.REACT_APP_SERVER_URL+ "/oauth/list_authorized_clients", 
      requestOptions).then((response)=>{
        if (response.status === 401){
          localStorage.removeItem('jwtAccessTokenLocalStorage');
          history.push('/auth/');
        }
        if (response.status >= 400 && response.status < 600) {
          throw new Error("Bad response from server");
        }
        return response;
      }).then((returnedResponse)=>{
        return returnedResponse;
      }).catch((error)=>{
        console.log(error)
      });
      const data = await response.json();
      const clients = data.clients;
      for(var i =0; i<clients.length; i++){
        const client = clients[i];
        if(client['client_id'] === selected){
          client['app_registration_since'] = moment.utc(client['app_registration_since']*1000).local().format() 
          sessionStorage.setItem('client_secret', client['client_secret']);
          setAppData(client);
        }
      }
    }
    async function getAuthToken(){
      // if there is no refresh token or auth_token in local retrieve authorization code
      const crequestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer '+jwt_access_token_json['value']
        },
        body: JSON.stringify({
          'client_id': selected
        })
      }; 
      const cresponse = await fetch( process.env.REACT_APP_SERVER_URL+ "/oauth/aucode",  
      crequestOptions).then((response)=>{
        if (response.status === 401){
          localStorage.removeItem('jwtAccessTokenLocalStorage');
          history.push('/auth/');
        }
        if (response.status >= 400 && response.status < 600) {
          // throw new Error("Bad response from server");
          console.log("error");
        }
        return response;
      }).then((returnedResponse)=>{
        return returnedResponse;
      }).catch((error)=>{
        console.log(error)
      });
      const cdata = await cresponse.json();
      const details ={
        'grant_type': 'refresh_token',
        'refresh_token': cdata['refresh_token'],
      }
      var formBody = [];
      for (var property in details){
        var encodedKey = encodeURIComponent(property);
        var encodedVal = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedVal);
      }
      const basic_secret = Buffer.from(selected+":"+sessionStorage.getItem('client_secret')).toString('base64')
      formBody = formBody.join("&");
      const token_requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Authorization': 'Basic '+ basic_secret 
          },
          body: formBody
      }; 
      const token_response = await fetch( process.env.REACT_APP_SERVER_URL+ 
      "/oauth/oauth_token",
      token_requestOptions).then((response)=>{
        if (response.status === 401) {
          console.log(response.json());
        }
        if (response.status >= 400 && response.status < 600) {
          // throw new Error("Bad response from server");
          console.log("error")
        }
        return response;
        }).then((returnedResponse)=>{
          return returnedResponse;
        }).catch((error)=>{
          console.log(error)
        });
        const auth_token = await token_response.json();
        localStorage.setItem('auth_token', JSON.stringify(auth_token));
    }
    fetchData();
    getAuthToken();
  }, []);
  const classes = useStyles();


  return (
    <div>
     <GridContainer>
        <GridItem xs={6} sm={6} md={6} lg={6}>
          <Card>
            <CardHeader color="rose" >
              <h4 className={classes.cardIconTitle}>Current App Information</h4>
            </CardHeader>
            <CardBody>
            <ListGroup>
              <ListGroupItem>Client_ID:{appdata.client_id}</ListGroupItem>
              <ListGroupItem>Client_Secret:{appdata.client_secret}</ListGroupItem>
              <ListGroupItem>App registration since:{appdata.app_registration_since}</ListGroupItem>
            </ListGroup>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer> 
    </div>
  );
}
