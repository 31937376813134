import React from 'react';
export default function AuthR(AuthRComponent) {
    return class extends React.Component {

        componentDidMount() {

            var jwtAccessTokenCache = localStorage.getItem('jwtAccessTokenLocalStorage');
            var authenticated = true;

            if (jwtAccessTokenCache) {

                // console.log('jwt_access_token found');
                var jwt_access_token_json = JSON.parse(jwtAccessTokenCache);
                if (jwt_access_token_json['expiry'] - new Date().getTime() <0) {
                    localStorage.removeItem('jwtAccessTokenLocalStorage');
                    authenticated = false;
                }
            } else {
                // console.log('jwt_access_token not found in local storage');
                authenticated = false;
            }
            if (!authenticated) {
                this.props.history.replace({ pathname: '/' });
            }


        }
        componentWillUnmount() {
        }
        
        render() {
            return (<AuthRComponent {...this.props} />);
        }
    }
}