import React from 'react';
import { useParams} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import EnhancedTable from './NoSelEnhancedTable';


function QueryResult(){

  const [data, setData] = React.useState([]);
  const {qid} = useParams();
  const [selected, setSelected] = React.useState(sessionStorage.getItem('selected_client'));
  const [clnsecret, setClnSecret] = React.useState(sessionStorage.getItem('client_secret'));
  const [authToken, setAuthToken] = React.useState(localStorage.getItem('auth_token'));
  var saved_token = JSON.parse(authToken);

  async function fetchToken(){
  
    const details ={
      'grant_type': 'refresh_token',
      'refresh_token': saved_token['refresh_token'],
    }
    var formBody = [];
    for (var property in details){
      var encodedKey = encodeURIComponent(property);
      var encodedVal = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedVal);
    }
    const basic_secret = Buffer.from(selected+":"+clnsecret).toString('base64')
    formBody = formBody.join("&");
    const token_requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          'Authorization': 'Basic '+ basic_secret 
        },
        body: formBody
    }; 
    const token_response = await fetch( process.env.REACT_APP_SERVER_URL+ 
    "/oauth/oauth_token",
    token_requestOptions).then((response)=>{
      if (response.status === 401) {
        console.log(response.json());
      }
      if (response.status >= 400 && response.status < 600) {
        // throw new Error("Bad response from server");
        console.log("error")
      }
      return response;
      }).then((returnedResponse)=>{
        return returnedResponse;
      }).catch((error)=>{
        console.log(error)
      });
      const auth_token = await token_response.json();
      localStorage.setItem('auth_token', JSON.stringify(auth_token));
  };

  async function fetchData(){
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer '+ saved_token['access_token']
      },
    };
    const response = await fetch(process.env.REACT_APP_SERVER_URL+"/stonecastle/query/" + qid, 
    requestOptions).then((response)=>{
    
      if (response.status >= 400 && response.status < 600) {
        console.log("error");
        throw new Error("Bad response from server");
      }
      return response;
    }).then((returnedResponse) => {
      return returnedResponse;
    }).catch((error) =>{
      console.log(error);
    });

    const data = await response.json();
    if (data.error){
      setData([{}]);
    }else{
      setData(data.details);
    }
  };

  React.useEffect(() => {
    try{
      fetchData();
    }catch(e){
      fetchToken();
      fetchData();
    }
  }, []);

  const columns = React.useMemo(
    () => [
     
      
      {
        Header: 'Account ID',
        accessor: 'bank_account_id',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'Rate',
        accessor: 'rate', 
      }
            
    ],
    []
  )

  const [skipPageReset, setSkipPageReset] = React.useState(false)

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row;
      })
    )
  }

  return (
    <div>
      <CssBaseline />
      <EnhancedTable
        // objectSelection='query'
        columns={columns}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
      />
    </div>
  )
}

export default QueryResult;
