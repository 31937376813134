/*eslint-disable*/
import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";

// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import {
  roundedLineChart,
  straightLinesChart,
  simpleBarChart,
  colouredLineChart,
  multipleBarsChart,
  colouredLinesChart,
  pieChart
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";

const useStyles = makeStyles(styles);

function Charts() {
  const classes = useStyles();

  const [hitdata, setHitData] = React.useState({});
  const [querydata, setQueryData] = React.useState({});
  const [queryamountdata, setQueryAmountData] = React.useState({});
  const [amountdata, setAmountData] = React.useState({});

  const [selected, setSelected] = React.useState(sessionStorage.getItem('selected_client'));
  const [clnsecret, setClnSecret] = React.useState(sessionStorage.getItem('client_secret'));
  const [authToken, setAuthToken] = React.useState(localStorage.getItem('auth_token'));
  var saved_token = JSON.parse(authToken);

  async function fetchToken(){
  
    const details ={
      'grant_type': 'refresh_token',
      'refresh_token': saved_token['refresh_token'],
    }
    var formBody = [];
    for (var property in details){
      var encodedKey = encodeURIComponent(property);
      var encodedVal = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedVal);
    }
    const basic_secret = Buffer.from(selected+":"+clnsecret).toString('base64')
    formBody = formBody.join("&");
    const token_requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          'Authorization': 'Basic '+ basic_secret 
        },
        body: formBody
    }; 
    const token_response = await fetch( process.env.REACT_APP_SERVER_URL+ 
    "/oauth/oauth_token",
    token_requestOptions).then((response)=>{
      if (response.status === 401) {
        console.log(response.json());
      }
      if (response.status >= 400 && response.status < 600) {
        // throw new Error("Bad response from server");
        console.log("error")
      }
      return response;
      }).then((returnedResponse)=>{
        return returnedResponse;
      }).catch((error)=>{
        console.log(error)
      });
      const auth_token = await token_response.json();
      localStorage.setItem('auth_token', JSON.stringify(auth_token));
  };


  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + saved_token['access_token']
    },
    body: JSON.stringify(
      { 
        "client_id": selected,
      }
    )
  };

  async function  fetchHitData() {
    
    const response = await fetch(process.env.REACT_APP_SERVER_URL+"/stonecastle/bank_account/mosthit", 
    requestOptions).then((response)=>{
      
      if (response.status >= 400 && response.status < 600) {
        console.log("error");
        throw new Error("Bad response from server");
      }
      return response;
    }).then((returnedResponse) => {
      return returnedResponse;
    }).catch((error) =>{
      console.log(error);
    });
    
    const resp_data = await response.json();
    setHitData(resp_data.data);
    setAmountData(resp_data.amount_data);
    var myTime = new Date();
    myTime.setMinutes(myTime.getMinutes() + 30);
  }

  async function  fetchQueryData() {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + saved_token['access_token']
        
      },
      body: JSON.stringify(
        { 
          "client_id": selected, 
           "limit": 100,
        }
      )
    };
    const response = await fetch(process.env.REACT_APP_SERVER_URL+"/stonecastle/query/stats", 
    requestOptions).then((response)=>{
      
      if (response.status >= 400 && response.status < 600) {
        console.log("error");
        throw new Error("Bad response from server");
      }
      return response;
    }).then((returnedResponse) => {
      return returnedResponse;
    }).catch((error) =>{
      console.log(error);
    });
    const resp_data = await response.json();
    setQueryData(resp_data.query_cnt_data);
    setQueryAmountData(resp_data.query_amount_dist);
    var myTime = new Date();
    myTime.setMinutes(myTime.getMinutes() + 30);
  };

  React.useEffect(() => {
    try{
      fetchHitData();
      fetchQueryData();
    }catch(e){
      fetchToken();
      fetchHitData();
      fetchQueryData(); 
    }
  },[]);


  return (
    <div>
      <Heading
        textAlign="center"
        title="Dashboard"
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader>
            <CardIcon color="info">
                <Timeline />
              </CardIcon>
            <h4 className={classes.cardTitle}>Query Stats</h4>
              <p className={classes.cardCategory}>Based on last 100 Queries order by date</p>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                // className="ct-chart-white-colors"
                data={querydata}
                type="Line"
                options={straightLinesChart.options}
                listener={straightLinesChart.animation}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader>
            <CardIcon color="rose">
                <Timeline />
              </CardIcon>
            <h4 className={classes.cardTitle}>Query Amount Distribution</h4>
              <p className={classes.cardCategory}>Based on Last 100 Queries</p>

            </CardHeader>
            <CardBody>
              <ChartistGraph
                // className="ct-chart-white-colors"
                data={queryamountdata}
                type="Line"
                options={straightLinesChart.options}
                listener={straightLinesChart.animation}
              />
            </CardBody>
          </Card>
        </GridItem>
        
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
              QueryResult: Most Hit Bank
              </h4>
            </CardHeader>
            <CardBody>
            <ChartistGraph
                // className="ct-chart-white-colors"
                data={hitdata}
                type="Bar"
                options={simpleBarChart.options}
                // responsiveOptions={simpleBarChart.responsiveOptions}
                listener={simpleBarChart.animation}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                QueryResult: WithDraw/Deposit by Most Hit Bank
              </h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                data={amountdata}
                type="Bar"
                options={multipleBarsChart.options}
                listener={multipleBarsChart.animation}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default Charts;
