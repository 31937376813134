import React from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from "react-hook-form";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";


const useStyles = makeStyles(styles);


export default function LoginPage() 
{
  const history = useHistory();
  // const [domain, setDomain] = React.useState(localStorage.getItem('domain'))
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  React.useEffect(() => {

    //localStorage.removeItem('jwtAccessTokenLocalStorage');
    var jwtAccessTokenCache = localStorage.getItem('jwtAccessTokenLocalStorage');
    
    if(jwtAccessTokenCache)
    {

      var jwt_access_token_json = JSON.parse(jwtAccessTokenCache);
      console.log(jwt_access_token_json['expiry']);
      console.log(new Date().getTime());
      console.log(jwt_access_token_json['expiry']> new Date());
      if(jwt_access_token_json['expiry'] - new Date().getTime()> 0 ){
        history.push("/auth/dashboard-page");
      }else{
        localStorage.removeItem('jwtAccessTokenLocalStorage');
        localStorage.removeItem('current_login_user');
        if(sessionStorage.getItem('selected_client')){
          sessionStorage.removeItem('selected_client');
        }
        if(localStorage.getItem('auth_token')){
          localStorage.removeItem('auth_token');
        }
      }
    }
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    
    return function cleanup() {
      window.clearTimeout(id);
    };
    
  });

  const classes = useStyles();
  
  const API_HEADERS_AND_MODE = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Frame-Options': 'Deny',
      'X-Content-Type-Options':'nosniff',
      'X-XSS-Protection':1
    },
  };
  const onSubmitForm= data => {
    localStorage.setItem('current_login_user',JSON.stringify(data['username']));
    fetch( process.env.REACT_APP_SERVER_URL+ '/user/login-api', {
      ...API_HEADERS_AND_MODE,
      method: 'POST',
      body: JSON.stringify(data),
    }).then(res => res.json())
      .then(response =>{
        // alert('Success:'+response.jwt_access_token);
        if(response.jwt_access_token){
          var myTime= new Date();
          myTime.setMinutes(myTime.getMinutes() + 30 ); 
          var data = {
            "value": response.jwt_access_token,
            "expiry": myTime.getTime()
          };
          localStorage.setItem('jwtAccessTokenLocalStorage',JSON.stringify(data));
          history.push("/auth/dashboard-page");
        }else{
          alert("Your username(email) or password doesn't match our record");
        }
      })
      .catch(error => console.error('Error:', error));
  };
  const { register, formState: { errors }, handleSubmit} = useForm({
    criteriaMode: 'all',
  });
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form  onSubmit={handleSubmit(onSubmitForm)}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Log in</h4>

              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    ...register("username", 
                    {
                      pattern: {
                        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Invalid Email Format'
                      },
                      maxLength: {
                        value: 50,
                        message: 'This input exceed maxLength.',
                      },
                      required: "Email is Required"
                    }
                    )
                  }}
                />
                 <ErrorMessage
                  errors={errors}
                  name="username"
                 
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <code  className="error-message" key={type}>{message}</code>
                    ))
                  }>
                </ErrorMessage>
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    ...register("password", 
                      {
                        minLength: {
                          value: 8,
                          message: 'This input has to be more than 8 character Length.',
                        },
                        required: "Password is required"
                      }
                    )
                  }}
                
              />
                <ErrorMessage
                  errors={errors}
                  name="password"

                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <code className="error-message" key={type}>{message}</code>
                    ))
                  }
                      />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="rose" simple size="lg" block type="submit">>
                  Let{"'"}s Go
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
