
// @material-ui/icons
import Dashboard from "views/Dashboard/Dashboard.js";
import DashboardIcon from "@material-ui/icons/Dashboard";
import castleAPI from 'views/Pages/Castlestoneapi.js';
import Query from 'views/Pages/Query.js';
import OAuthApp from 'views/Pages/OAuthApplication.js';
import Users from 'views/Pages/Users.js';
import Bank from 'views/Pages/Bank.js';
import BankAccount from 'views/Pages/BankAccount.js';
import Chart from 'views/Pages/Charts.js';
var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/creditadmin"
  },
  {
    collapse: true,
    name: "Application",
    state: "appCollapse",
    views: [
      {
        path: "/oauth2app",
        name: "Applications",
        component: OAuthApp,
        layout: "/creditadmin"
      },
      {
        path: "/users",
        name: "Users",
        component: Users,
        layout: "/creditadmin"
      }
    ]
  },
  {
    collapse: true,
    name: "Data",
    state: "dataCollapse",
    views: [
      {
        path: "/bank",
        name: "Bank",
        component: Bank,
        layout: "/creditadmin"
      },
      {
        path: "/account",
        name: "Bank Account",
        component: BankAccount,
        layout: "/creditadmin"
      }
    ]
  },
  {
    collapse: true,
    name: "Models",
    state: "modelCollapse",
    views: [
      {
        path: "/API",
        name: "API",
        component: castleAPI,
        layout: "/creditadmin"
      },
    ]
  },
  {
    collapse: true,
    name: "WorkBench",
    state: "workbenchCollapse",
    views: [
      {
        path: "/query",
        name: "Query",
        component: Query,
        layout: "/creditadmin"
      },
      {
        path: "/chart",
        name: "Chart",
        component: Chart,
        layout: "/creditadmin"
      }
    ]
  }
  
];
export default dashRoutes;
