/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import CreditAdminLayout from "layouts/CreditAdmin.js";
import LoginLayout from "layouts/Auth.js";
import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import RequireAuth from "views/Components/RequireAuth.js";

const hist = createBrowserHistory();
// const login = <LoginLayout layout="/auth" />;

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/creditadmin" component={RequireAuth(CreditAdminLayout)} />
      <Route path="/auth" component={LoginLayout} />
      <Redirect from="/" to="/auth" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
