import React from 'react'

import MaUTable from '@material-ui/core/Table'
import PropTypes from 'prop-types'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableToolbar from './TableToolbar'
import {
  useGlobalFilter,
  usePagination,
  manualPagination,
  useSortBy,
  useTable,
} from 'react-table'


const inputStyle = {
  padding: 0,
  margin: 0,
  border: 0,
  background: 'transparent',
  color: '#777777',
  width: "300px"
}

const EnhancedTableServePage = ({
  columns,
  data,
  fetchData,
  pageCount: controlledPageCount,
}) => {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize , globalFilter},
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 25,  },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useGlobalFilter,
    usePagination,
    
  )

  React.useEffect(() => {
    fetchData({pageIndex, pageSize});
    
  }, [pageIndex, pageSize]);
  


  const removeByIndexs = (array, indexs) =>
    array.filter((_, i) => !indexs.includes(i))

  // Render the UI for your table
  return (
    <div>
    <TableContainer>
      <TableToolbar
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
      />
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell
                //   {...(column.id === 'selection'
                //     ? column.getHeaderProps()
                //     : column.getHeaderProps(column.getSortByToggleProps()))}
                >
                  {column.render('Header')}
                  {/* {column.id !== 'selection' ? (
                    <TableSortLabel
                      active={column.isSorted}
                      // react-table has a unsorted state which is not treated here
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                    />
                  ) : null} */}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
        </MaUTable>
    </TableContainer>
    <React.Fragment>
    <div>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
     </div> 
     </React.Fragment>
     </div>
  )

}

EnhancedTableServePage.propTypes = {
  // objectSelection: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  fetchData: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired
}

export default EnhancedTableServePage
